let resizeTimeout
const navDesktop = document.querySelector('.navbar-content.fixed-top')
const mainElem = document.querySelector('main')
const navbars = document.querySelectorAll('.navbar-content.fixed-top , .navbar-control.fixed-top');

const setMenuHeight = () => {
    if (window.innerWidth > 991) {
        mainElem.style.marginTop = `${navDesktop.offsetHeight}px`
    } else {
        mainElem.style.marginTop = '58px'
    }
}

const initMenuSticky = () => {
    setMenuHeight()

    if (navDesktop) {
        window.addEventListener('scroll', () => {
            for (const nav of navbars) {
                window.scrollY > 120
                ? nav.classList.add('page-scroll')
                : nav.classList.remove('page-scroll')
            }
        })
    }

    window.addEventListener('resize', () => {
        if (resizeTimeout) {
            clearTimeout(resizeTimeout)
        }
        resizeTimeout = setTimeout(() => {
            setMenuHeight()
        }, 500)
    })
}

export { initMenuSticky }
