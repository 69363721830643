/**
 *
 * @param {*} sliderIndex
 * @param {*} breakpoint
 * @param {*} elem
 * @param {*} bg
 */
const setArrow = (sliderIndex, breakpoint, elem, bg) => {
    Number(sliderIndex) === breakpoint
        ? elem.classList.add(bg)
        : elem.classList.remove(bg)
}

/**
 *
 * @param {*} slider
 * @param {*} sliderIndex
 */
const setSliderNumber = (slider, sliderIndex) => {
    let maxNumber = 0
    for (const numberOfItems of slider.querySelectorAll('[data-item-number]')) {
        const nextNumber = numberOfItems.dataset.itemNumber
        if (maxNumber < nextNumber) {
            maxNumber = nextNumber
        }
    }
    slider.querySelector('.control-slick-item-counter').textContent = `${sliderIndex}/${maxNumber}`

    setArrow(sliderIndex, 1, slider.querySelector('.control-slick-prev'), 'bg-light')
    setArrow(sliderIndex, 7, slider.querySelector('.control-slick-next'), 'bg-light')
}

// Funkce pro sledování změn třídy 'slick-current'

/**
 *
 * @param {*} slider
 */
const observeSlickCurrent = (slider) => {
    const items = slider.querySelectorAll('.item')

    // Configuration object for MutationObserver
    const config = { attributes: true, attributeFilter: ['class'] }

    // Callback function that is triggered when a change is made
    const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                if (mutation.target.classList.contains('slick-current')) {
                    setSliderNumber(slider, mutation.target.dataset.itemNumber)
                }
            }
        }
    }
    const observer = new MutationObserver(callback)

    // Start observation on each slider item
    items.forEach(item => {
        observer.observe(item, config)
    })
}

/**
 *
 * @param {*} controlSlick
 * @param {*} slider
 */
const scrollControl = (controlSlick, slider) => {
    // Set item counter after loaded HTML DOM
    setSliderNumber(slider, 1)

    controlSlick.addEventListener('click', (e) => {
        if (
            e.target.classList.contains('control-slick-prev') &&
            !e.target.classList.contains('bg-light') ||
            e.target.classList.contains('control-slick-prev-img') &&
            !e.target.parentNode.classList.contains('bg-light')
        ) {
            slider.querySelector('.slick-prev').click()
        }

        if (
            e.target.classList.contains('control-slick-next') &&
            !e.target.classList.contains('bg-light') ||
            e.target.classList.contains('control-slick-next-img') &&
            !e.target.parentNode.classList.contains('bg-light')
        ) {
            slider.querySelector('.slick-next').click()
        }
    })
    observeSlickCurrent(slider)
}

/**
 *
 * @param {*} intervalId
 */
const checkForSlickClass = (intervalId) => {
    if (document.querySelector('.slick-container')) {
        clearInterval(intervalId)
        for (const slider of document.querySelectorAll('.slick-container')) {
            slider.insertAdjacentHTML('beforeEnd', `
                <aside class="control-slick d-flex align-items-center justify-content-center">
                    <div class="control-slick-prev">
                        <img class="control-slick-prev-img"
                            src='/../static/img/arrow-slick-prev.svg' alt="slick previous control">
                    </div>
                    <div class="control-slick-item-counter mx-50"></div>
                    <div class="control-slick-next">
                        <img class="control-slick-next-img"
                            src='/../static/img/arrow-slick-next.svg' alt="slick next control">
                    </div>
                </aside>
            `)
            scrollControl(slider.querySelector('.control-slick'), slider)
        }
    }
}

/**
 *
 * @param {*} func
 * @param {*} delay
 * @returns
 */
const throttle = (func, delay) => {
    let lastCall = 0
    return function(...args) {
        const now = new Date().getTime()
        if (now - lastCall >= delay) {
            lastCall = now
            func(...args)
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const intervalId = setInterval(throttle(() => checkForSlickClass(intervalId), 1000), 1000)
})
