// Custom check form fields.

const showMessage = (node) => {
    node.classList.remove("d-none")
}


const hideMessage = (node) => {
    node.classList.add("d-none")
}


const parseDefinition = (form) => {
    let response = []
    if (!form.dataset.custom_check_fields) {
        return response
    }
    form.dataset.custom_check_fields.split(" ").forEach(definition => {
        if (definition.indexOf("|") === -1) {
            console.error(`Definition "${definition}" in not in format "selector|selector".`)
        } else {
            const data = definition.split("|")
            const fields = data[0].split(";")
            const nodeMsg = document.querySelector(data[1])
            if (nodeMsg) {
                let form_fields = []
                for (const i in fields) {
                    const field = document.querySelector(fields[i])
                    if (field) {
                        form_fields.push(field)
                    }
                }
                if (form_fields) {
                    response.push([form_fields, nodeMsg])
                }
            }
        }
    })
    return response
}

const fieldsNotReady = (fields) => {
    let not_ready = 0
    for (const i in fields) {
        const field = fields[i]
        if (field.tagName == "INPUT") {
            if (field.type == "checkbox" || field.type == "radio") {
                if (!field.checked) {
                    not_ready += 1
                }
            } else {
                if (!field.value) {
                    not_ready += 1
                }
            }
        } else if (field.tagName == "SELECT") {
            if (!field.value) {
                not_ready += 1
            }
        } else {
            if (!field.textContent.length) {
                not_ready += 1
            }
        }
    }
    return not_ready == fields.length
}


const checkFields = (event) => {
    const form = event.target.closest('form')
    if (form) {
        const defs = parseDefinition(form)
        for (const i in defs) {
            hideMessage(defs[i][1])
        }
        for (const i in defs) {
            const fields = defs[i][0]
            const nodeMsg = defs[i][1]
            if (fieldsNotReady(fields)) {
                showMessage(nodeMsg)
                event.preventDefault()
                return
            }
        }
    }
}


export const customCheckFormFields = () => {
    const button = document.querySelector('.custom-check-form-fields')
    if (button) {
        button.addEventListener("click", checkFields)
    }
}
